@function multiple-box-shadow($n) {
  $value: '#{random(2000)}px #{random(2000)}px #fff';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #bfbfbf';
  }
  @return unquote($value);
}

$shadows-small: multiple-box-shadow(1400);
$shadows-medium: multiple-box-shadow(400);
$shadows-big: multiple-box-shadow(200);

html {
  height: 100vh;
  background: radial-gradient(ellipse at bottom, #1a1a1a 5%, #252525 100%);
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 10s linear infinite;
  position: absolute; // Add this line
  top: -2000px; // Add this line

  &:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 15s linear infinite;
  position: absolute; // Add this line
  top: -2000px; // Add this line

  &:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 25s linear infinite;
  position: absolute; // Add this line
  top: -2000px; // Add this line

  &:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(100vh + 2000px)); // Update the value here
  }
}