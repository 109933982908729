
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import 'animate.css';

   body{
        background-color: #252525;
        font-family: "Roboto Mono", monospace;
    }
  
  
/* For webkit based browsers like Chrome and Safari */
::-webkit-scrollbar {
    width: 4px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #6b6b6b; /* color of the track */
}

::-webkit-scrollbar-thumb {
    background: red; /* color of the thumb */
    border-radius: 1px; /* roundness of the thumb */
}



