@import 'animate.css';
.hoverable-letter {
    display: inline-block;
    opacity: 1;
    animation: bounceIn;
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
  }
  
  .hoverable-letter:hover {
    display: inline-block;
    animation-fill-mode: both;
    animation: rubberBand 1s;
    opacity: 1; 
  
    &:hover {
      animation: none;
      color: #ff4500;
    }
  }
 