@font-face {
    font-family: 'Coolvetica';
    src: url('../../assests/fonts/CoolveticaRg-Regular.woff');
  }
.hoverable-text {
    color: white;
    font-size: 56px;
    font-weight: 500;
    cursor:default;
  font-family:Coolvetica;
  word-wrap: normal;
  letter-spacing: 2px;
  margin: 0;
  }

  @media (max-width: 992px) {
    .hoverable-letter {
        font-size: 33px;
        letter-spacing: 1px;
    }
    .hoverable-text {
      line-height: 0.8;
    }
}
.special-letter{
    color: red;
}
