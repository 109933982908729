@import 'animate.css';
.animated-pointer {
    position: fixed;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ff7373;
    pointer-events: none;
    z-index: 9999;
    box-shadow: 0 0 12px rgba(245, 52, 52, 0.7);
    mix-blend-mode: difference;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  @media (max-width: 992px) {
    .animated-pointer {
      display: none;
    }
}
.animated-pointer.visible {
    opacity: 1;
  }
  
  @keyframes delayedMove {
    0% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(-50%, -50%) translate(calc(-50% + 9px), calc(-50% + 9px));
    }
  }
  
  .animated-pointer.visible {
    animation: delayedMove 0.2ms ease-out infinite alternate;
  }

  