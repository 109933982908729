@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');


.tags{
    color: red;
    font-family: "Caveat", cursive;
    font-size: 20px;
    cursor: default;
    white-space:pre;
}
.tag-main{
margin: 20px;

}

.hometext{
    display: flex;
    flex-direction: column;
    padding: 40px;
    justify-content: center;
    
}
.homep{
    color: red;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    cursor: default;
}
.main{
    display: flex;
    justify-content: space-between;
    margin-left: 65px;
    align-items: center;
    height: 100vh;
}
.dpdiv{
    display: flex;
    justify-content: center;
    align-items: center;    
    padding: 40px;
}
.dp{
    width: 350px;
    border-radius: 50%;
    box-shadow: 5px 5px rgb(238, 238, 238,0.2);
    z-index: 2;
    opacity: 0.8;
    transition: .5s;
    transform:perspective(400px) translateZ(-100px);
   
}
.dp:hover{
    animation: dp 2s ease-in-out infinite;
    transform: perspective(400px)   translateZ(-50px);
    opacity: 1;
}

@keyframes dp {
    0% {
        box-shadow: 0 0 7px 0 rgba(255, 255, 255, 0.5);
      }
      70% {
        box-shadow: 0 0 7px 10px rgba(255, 255, 255, 0.4);
      }
      100% {
        box-shadow: 0 0 7px 0 rgba(255, 255, 255, 0.4);
      }
    
  }
  @media (max-width: 992px) {

    .main{
        flex-direction: column;
        margin-left: 0;
        margin-top: 65px;
        height: auto;
    }
    .dp{
        animation: dp 2s ease-in-out infinite;
    }
    .homep{
        font-size: 15px;
    }

  
}