@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
.topbar{
    background-color: #181818;
    width: 65px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.mobnav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.logo{
    width: 50px;
    height: 50px;
    transition: transform 0.3s;
    margin: 0px 10px;
    cursor: pointer;
}
.logo:hover {
    transform:rotateX(30deg) scale(1.1);

}
.navbar{
display: flex;
align-items: center ;
}

nav{
display: flex;
flex-direction: column;
}
.navlink{
    color: white;
    cursor: pointer;
    padding: 25px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;

}
.nav-img{
    width: 25px;
    height: 25px;
    display: block;
    transition: 1s;
 
}
.svg-color{
    fill: #4d4d4e;
}
.navlink>span{
    display: none;
    transition: 1s;
    font-weight: 600;
    font-size: 13px;
    opacity: 0.9;
    color: red;
}
.navlink:hover>span{
    display: block;
    
}
.navlink:hover>svg{
    display: none;
  
}
ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}
ul a svg{
    width: 20px;
    height: 20px;
    padding: 10px;
    cursor: pointer;
}
ul a svg path{
   fill: rgb(197, 197, 197)  ;
}

ul a:hover>svg{
transform: scale(1.1);
opacity: 0.7;
}
.icon{
background-color: transparent;
outline: none;
border: none;
text-align: center;
display: none;
transition: transform 0.3s ease-in;
margin: 0px 10px;
}
.iconrev{
transform: rotateX(180deg);
}
.menu{
color: red;
opacity: 0.8;
font-size:35px;
}

@media (max-width: 992px) {
  .topbar{
    width: 100%;
    height: 60px;
  }
    ul a{
        display: none;
    }
    nav{
        display: none;
    }
    .mobnav{
      width: 100%;
    }

    .navlink:hover>svg{
        display: block;
      
    }
    .open{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
    .topbar{
        flex-direction: column;
        height: auto;
        align-items: flex-start;
    }
    .navlink>span{
        display: block;
        padding-left: 20px;
        font-size: 18px;

    }
    .navlink{
        width: 100%;
        padding: 15px;
    }
    
    .icon{
        display: block;
    }
}

.active-section{
    fill: red;
    opacity: 0.8;
  }